import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';
import {
  Routes,
  Route
} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { useDimensionContainer } from '../hooks';
import { veryVeryLightGrey } from '../config/colors';
// import components
import Menu from '../components/molecules/Menu';
import PlayerScreen from '../components/organisms/PlayerScreen';
import AccountScreen from '../components/organisms/AccountScreen';
import PlayerBar from '../components/molecules/PlayerBar';
import ScheduleScreen from '../components/organisms/ScheduleScreen';
import ScheduleItemScreen from '../components/organisms/ScheduleItemScreen';
import Screen404 from '../components/organisms/Screen404';
import LibraryScreen from '../components/organisms/LibraryScreen';
import StationScreen from '../components/organisms/StationScreen';
import PlaylistScreen from '../components/organisms/PlaylistScreen';

export type Screens = "Player" | "Library" | "Account"; 
export type IndexScreen = {[key: string]: Screens};
export const indexScreen: IndexScreen = {
  "1": "Player",
  "2": "Library",
  "3": "Account",
}

interface Props {};

const AppLayout: React.FC<Props> = props => {

  function onCloseDrawersPlayer() {
    navigate("/");
    setActiveScreen('1');
  }
  function onCloseDrawersLibrary() {
    navigate("/library");
    setActiveScreen('2');
  }
  const navigate = useNavigate();
  const refContainer = useRef(null);
  const { width } = useDimensionContainer(refContainer);

  const [ activeScreen, setActiveScreen] = useState<string>('1');
  const [ widthFixModal, setWidthFixModal ] = useState<boolean>(false);

  useEffect(() => {
    if (width*0.4 <= 400) {
      setWidthFixModal(true);
    } else {
      setWidthFixModal(false);
    }
  }, [width]);
  

  return (
    <Container ref={refContainer}>
      <Menu activeScreen={activeScreen} setActiveScreen={setActiveScreen} />
      <Content>
        <Routes>
          <Route path="/" element={<PlayerScreen />}>
            <Route
              path="account/*"
              element={
                <Drawer
                  placement="right"
                  closable={false}
                  onClose={onCloseDrawersPlayer}
                  visible={true}
                  width={widthFixModal ? 400 : '40%'}
                >
                  <AccountScreen />
                </Drawer>
              }
            />
          </Route>
          <Route
              path="/library"
              element={<LibraryScreen />}
          >
            <Route
              path="station/*"
              element={
                <Drawer
                  placement="right"
                  closable={false}
                  onClose={onCloseDrawersLibrary}
                  visible={true}
                  width={widthFixModal ? 400 : '40%'}
                  drawerStyle={{ backgroundColor: veryVeryLightGrey}}
                >
                  <StationScreen />
                </Drawer>
              }
            />
            <Route
              path="playlist/*"
              element={
                <Drawer
                  placement="right"
                  closable={false}
                  onClose={onCloseDrawersLibrary}
                  visible={true}
                  width={widthFixModal ? 400 : '40%'}
                  drawerStyle={{ backgroundColor: veryVeryLightGrey}}
                >
                  <PlaylistScreen />
                </Drawer>
              }
            />
          </Route>
          <Route
              path="/calendar/*"
              element={<ScheduleScreen />}
            >
              <Route
                path="item/*"
                element={
                  <Drawer
                    placement="right"
                    closable={false}
                    visible={true}
                    width={widthFixModal ? 400 : '40%'}
                    drawerStyle={{ backgroundColor: veryVeryLightGrey}}
                  >
                    <ScheduleItemScreen />
                  </Drawer>
                }
              />  
            </Route>
        </Routes>
      </Content>
      <PlayerBar />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  height: 100vh;
  width: 100vw;
`;

const Content = styled.main`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  overflow: hidden;
  z-index: 1;
  position: relative;
`;
export default AppLayout;