import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AccountScreen } from '../../store/modelApp';
import { useApp, usePlayer } from '../../store';
import { loadUserProfile } from '../../services/SoundSuitService';
// import components
import Title from '../atoms/Title';
import ButtonMenu from '../molecules/ButtonMenu';
import AccountCard from '../molecules/AccountCard';
import { redLightBG, redText } from '../../config/colors';

interface Routes {
  [arg: number]: string;
};

const routes: Routes = {
  1: 'infos',
  2: 'zones',
  3: 'tracks',
  4: 'language',
  5: 'refer'
};

interface Props {};

const AccountMenu: React.FC<Props> = props => {

  function handlePressOnButton(index: number, screen: AccountScreen): void {
    if (screen !== AccountScreen.SupportAccount) {
      setAccountScreen(screen);
    }

    navigate(routes[index]);

  }

  const [ t ] = useTranslation();
  const navigate = useNavigate();

  const zoneSelected = usePlayer(state => state.state.zoneSelected);
  const accountScreen = useApp(appState => appState.state.accountScreen);
  const setAccountScreen = useApp.getState().reducers.setAccountScreen;
  const logout = useApp.getState().effects.logout;

  const { data } = useQuery('/userinfo', loadUserProfile);

  return (
    <Container>
      <Header>
        <Title>{t('Account.profileTitle')}</Title>
      </Header>
      <AccountCard
        zoneId={zoneSelected || data?.zones?.[0] || undefined}
        locationId={data?.locations?.[0] || undefined}
        name={data?.first_name || '' + data?.last_name || ''}
      />
      <WrapperButtons>
        <ButtonMenu 
          top
          title={t('Account.businessInfo')}
          bottom
          icon="info"
          active={accountScreen === AccountScreen.InfoAccount}
          handlePress={() => handlePressOnButton(1, AccountScreen.InfoAccount)}
        /> 
        <ButtonMenu 
          top
          title={t('Account.musicZones')}
          bottom
          icon="zones"
          active={accountScreen === AccountScreen.ZonesAccount}
          handlePress={() => handlePressOnButton(2, AccountScreen.ZonesAccount)}
        /> 
        <ButtonMenu 
          title={t('Account.likesBannedTracks')}
          bottom
          icon="tracks"
          active={accountScreen === AccountScreen.TracksAccount}
          handlePress={() => handlePressOnButton(3, AccountScreen.TracksAccount)}
        /> 
        <ButtonMenu 
          title={t('Account.language')}
          bottom
          icon="language"
          active={accountScreen === AccountScreen.LanguageAccount}
          handlePress={() => handlePressOnButton(4, AccountScreen.LanguageAccount)}
        /> 
        {data?.subscriptionState === 'PAID' && (
          <ButtonMenu 
            title={t('Account.referABusiness')}
            bottom
            icon="refer"
            active={accountScreen === AccountScreen.ReferBusiness}
            handlePress={() => handlePressOnButton(5, AccountScreen.ReferBusiness)}
          /> 
        )}
      </WrapperButtons>
      <ButtonPricing onClick={() => window.open("https://soundsuit.fm/pricing/", '_blank')}>
        UPGRADE NOW
      </ButtonPricing>
      <WrapperLogOut>
        <ButtonMenu 
            title={t('Account.logout')}
            icon="logout"
            logout
            active={false}
            handlePress={logout}
          /> 
      </WrapperLogOut>
    </Container>
  );
}


const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
`;
const DetailsCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;
const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
`;
const WrapperLogOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
`;
const ButtonPricing = styled.button`
  margin-top: 40px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: ${redLightBG};
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${redText};
  }
`;

export default AccountMenu;