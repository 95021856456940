import React, { useState } from 'react';
import styled from 'styled-components';
import { Slider } from 'antd';
import Sound from 'react-hifi';

import { secondsToTimeFormat } from '../../utils/functions';
import { black, veryLightGrey, redLightBG } from '../../config/colors';
import { usePlayer } from '../../store';
// import components
import Text from '../atoms/Text';

interface Props {};

const ProgressPlayer: React.FC<Props> = props => {

  function onValueChange(value: number) {
    if (!isSeeking) {
      setIsSeeking(true);
    }
    setSeek(value);
    setCurrentTrackData({
      ...currentData,
      position: value
    });
  }

  async function onSlidingComplete(value: number) {
    setIsSeeking(false);
  }

  const [ isSeeking, setIsSeeking ] = useState<boolean>(false);
  const [ seek, setSeek ] = useState<number>(0);

  const currentData = usePlayer(playerState => playerState.state.currentTrackData);
  const status = usePlayer(playerState => playerState.state.status);

  const setCurrentTrackData = usePlayer.getState().reducers.setCurrentTrackData;

  const { duration, position } = currentData;

  if (status === Sound.status.STOPPED) {
    return (
      <Container>
        <ContainerProgressBar>
          <ProgressBarInitial />
        </ContainerProgressBar>
        <WrapperTime>
          <Text size="small"color={black} >00:00</Text>
          <Text size="small"color={veryLightGrey} >00:00</Text>
        </WrapperTime>
      </Container>
    );
  }

  return (
    <Container>
      <WrapperSlider>
        <Slider
          onChange={onValueChange}
          onAfterChange={onSlidingComplete}
          value={position}
          min={0}
          max={duration}
          tooltipVisible={false}
          trackStyle={{
            backgroundColor: redLightBG
          }}
          handleStyle={{
            opacity: 0
          }}
          step={0.01}
        />
      </WrapperSlider>
      <WrapperTime>
        <Text size="small"color={black} >{isSeeking ? secondsToTimeFormat(seek) : !isNaN(position) ? secondsToTimeFormat(position): "00:00"}</Text>
        <Text size="small"color={veryLightGrey} >{ !isNaN(duration) ? secondsToTimeFormat(duration) : "00:00"}</Text>
      </WrapperTime>
    </Container>
  );
}

const Container = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
`;
const ContainerProgressBar = styled.div`
  height: 30px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ProgressBarInitial = styled.div`
  height: 3px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${veryLightGrey};
`;
const WrapperSlider = styled.div`
  width: 90%;
  height: 30px;
`;
const WrapperTime= styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default ProgressPlayer;
