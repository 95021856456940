import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Bars } from 'react-loading-icons';

import { Days } from '../../services/SoundSuitServiceTypes';
import {
  startHourSchedule,
  nbHourDaySchedule,
  heightTrancheScheduleTablet,
  heightLineTimeSchedule,
  leftMarginVerticalScheduleTablet,
} from '../../config/hardData';
import {
  grey,
  veryLightLightGrey,
  buttonRed,
  redLightBG,
  white
} from '../../config/colors';
import { usePlayer, useApp } from '../../store';
import {
  getTopItemSchedule,
  transformDatetoHourString
} from '../../utils/functions';
import { useSchedule } from '../../hooks';
// import components
import Text from '../atoms/Text';
import FlatButton from './FlatButton';
import ItemSchedule from './ItemSchedule';

interface Props {
  loading: boolean;
  widthColumn: number;
  idCalendar: string;
};

const days: Days[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const CalendarTablet: React.FC<Props> = props => {

  function handleScrollLeft(event) {
    const {
      target: {
        scrollLeft
      }
    } = event;
    setScrollLeft(scrollLeft);
  }

  function getHourToString(i: number): string {
    return `${(startHourSchedule + i).toString().padStart(2, "0")}:00`;
  }

  const {
    loading,
    widthColumn,
    idCalendar
  } = props;

  const { sortTimeslotsByDay } = useSchedule();

  const setDaySelected = usePlayer.getState().reducers.setScheduleDaySelected;
  const daySelected = usePlayer(statePlayer => statePlayer.state.scheduleDaySelected);
  const timeslots = usePlayer(statePlayer => statePlayer.state.timeslots);
  const menuOn = useApp(state => state.state.menuOn);

  const [ currentTimeTop, setCurrentTimeTop ] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const { t } = useTranslation();

  useEffect(() => {
    const date = new Date();
    const stringTime = transformDatetoHourString(date);
    setCurrentTimeTop(getTopItemSchedule(stringTime));
  }, []);

  const leftMarginDays = useMemo(() => ({
    monday: leftMarginVerticalScheduleTablet,
    tuesday: leftMarginVerticalScheduleTablet + widthColumn,
    wednesday: leftMarginVerticalScheduleTablet + widthColumn * 2,
    thursday: leftMarginVerticalScheduleTablet + widthColumn * 3,
    friday: leftMarginVerticalScheduleTablet + widthColumn * 4,
    saturday: leftMarginVerticalScheduleTablet + widthColumn * 5,
    sunday: leftMarginVerticalScheduleTablet + widthColumn * 6
  }), [widthColumn]);

  const timeSlotsMon = useMemo(() => {
    return sortTimeslotsByDay(timeslots, 'monday');
  }, [timeslots, sortTimeslotsByDay]);
  const timeSlotsTue = useMemo(() => {
    return sortTimeslotsByDay(timeslots, 'tuesday');
  }, [timeslots, sortTimeslotsByDay]);
  const timeSlotsWed = useMemo(() => {
    return sortTimeslotsByDay(timeslots, 'wednesday');
  }, [timeslots, sortTimeslotsByDay]);
  const timeSlotsThu = useMemo(() => {
    return sortTimeslotsByDay(timeslots, 'thursday');
  }, [timeslots, sortTimeslotsByDay]);
  const timeSlotsFri = useMemo(() => {
    return sortTimeslotsByDay(timeslots, 'friday');
  }, [timeslots, sortTimeslotsByDay]);
  const timeSlotsSat = useMemo(() => {
    return sortTimeslotsByDay(timeslots, 'saturday');
  }, [timeslots, sortTimeslotsByDay]);
  const timeSlotsSun = useMemo(() => {
    return sortTimeslotsByDay(timeslots, 'sunday');
  }, [timeslots, sortTimeslotsByDay]);

  const mountRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      node.scrollTo({top: currentTimeTop - 100, left: leftMarginDays[daySelected], behavior: 'smooth' });
    }
  }, [currentTimeTop, daySelected, leftMarginDays]);

  if (loading) {
    return (
      <WrapperLoading>
        <Bars speed={2} fill={redLightBG} stroke={redLightBG} height="3em" />
      </WrapperLoading>
    );
  }

  return (
    <Container
      ref={mountRef}
      onScroll={handleScrollLeft}
    >
      <div></div>
      <CalendarWrapper>
        <CurrentTimeLine
          top={currentTimeTop}
          left={leftMarginDays[daySelected]}
          width={widthColumn}
        >
          <Circle />
        </CurrentTimeLine>
        <DaysBar>
          {days.map((day, i) => {
            return (
              <VerticalTrancheDay
                key={`label-${day}-${i}`}
                index={i}
                width={widthColumn}
              >
                <FlatButton
                  active={day === daySelected}
                  handlePress={setDaySelected}
                  id={day}
                  colorText='black'
                  width={widthColumn}
                  inactiveColor={white}
                >
                    {t(`Schedule.${day}`)}
                </FlatButton>
              </VerticalTrancheDay>
            );
          })}
        </DaysBar>
        {[...Array(nbHourDaySchedule).keys()].map(i => {
          return (
            <Tranche index={i} key={`tranche-hour-${i}`}>
              <Text size="small" color={grey} >{getHourToString(i)}</Text>
              <SeparationLine />
            </Tranche>
          );
        })}
        {days.map((day, i) => {
          return (
            <VerticalTranche
              key={`${day}-${i}`}
              index={i}
              width={widthColumn}
            />
          );
        })}
        {timeSlotsMon.map((item, i) => {
          const startLimit = i > 0 ? timeSlotsMon[i-1].timeSlot.end.hour : undefined;
          const endLimit = i < timeSlotsMon.length -1 ? timeSlotsMon[i+1].timeSlot.start.hour : undefined;
          return (
            <ItemSchedule
              key={`item-${i}`}
              data={item}
              startLimit={startLimit}
              endLimit={endLimit}
              widthColumn={widthColumn}
              leftPos={leftMarginDays['monday']}
              idCalendar={idCalendar}
            />
          );
        })}
        {timeSlotsTue.map((item, i) => {
          const startLimit = i > 0 ? timeSlotsTue[i-1].timeSlot.end.hour : undefined;
          const endLimit = i < timeSlotsTue.length -1 ? timeSlotsTue[i+1].timeSlot.start.hour : undefined;
          return (
            <ItemSchedule
              key={`item-${i}`}
              data={item}
              startLimit={startLimit}
              endLimit={endLimit}
              widthColumn={widthColumn}
              leftPos={leftMarginDays['tuesday']}
              idCalendar={idCalendar}
            />
          );
        })}
        {timeSlotsWed.map((item, i) => {
          const startLimit = i > 0 ? timeSlotsWed[i-1].timeSlot.end.hour : undefined;
          const endLimit = i < timeSlotsWed.length -1 ? timeSlotsWed[i+1].timeSlot.start.hour : undefined;
          return (
            <ItemSchedule
              key={`item-${i}`}
              data={item}
              startLimit={startLimit}
              endLimit={endLimit}
              widthColumn={widthColumn}
              leftPos={leftMarginDays['wednesday']}
              idCalendar={idCalendar}
            />
          );
        })}
        {timeSlotsThu.map((item, i) => {
          const startLimit = i > 0 ? timeSlotsThu[i-1].timeSlot.end.hour : undefined;
          const endLimit = i < timeSlotsThu.length -1 ? timeSlotsThu[i+1].timeSlot.start.hour : undefined;
          return (
            <ItemSchedule
              key={`item-${i}`}
              data={item}
              startLimit={startLimit}
              endLimit={endLimit}
              widthColumn={widthColumn}
              leftPos={leftMarginDays['thursday']}
              idCalendar={idCalendar}
            />
          );
        })}
        {timeSlotsFri.map((item, i) => {
          const startLimit = i > 0 ? timeSlotsFri[i-1].timeSlot.end.hour : undefined;
          const endLimit = i < timeSlotsFri.length -1 ? timeSlotsFri[i+1].timeSlot.start.hour : undefined;
          return (
            <ItemSchedule
              key={`item-${i}`}
              data={item}
              startLimit={startLimit}
              endLimit={endLimit}
              widthColumn={widthColumn}
              leftPos={leftMarginDays['friday']}
              idCalendar={idCalendar}
            />
          );
        })}
        {timeSlotsSat.map((item, i) => {
          const startLimit = i > 0 ? timeSlotsSat[i-1].timeSlot.end.hour : undefined;
          const endLimit = i < timeSlotsSat.length -1 ? timeSlotsSat[i+1].timeSlot.start.hour : undefined;
          return (
            <ItemSchedule
              key={`item-${i}`}
              data={item}
              startLimit={startLimit}
              endLimit={endLimit}
              widthColumn={widthColumn}
              leftPos={leftMarginDays['saturday']}
              idCalendar={idCalendar}
            />
          );
        })}
        {timeSlotsSun.map((item, i) => {
          const startLimit = i > 0 ? timeSlotsSun[i-1].timeSlot.end.hour : undefined;
          const endLimit = i < timeSlotsSun.length -1 ? timeSlotsSun[i+1].timeSlot.start.hour : undefined;
          return (
            <ItemSchedule
              key={`item-${i}`}
              data={item}
              startLimit={startLimit}
              endLimit={endLimit}
              widthColumn={widthColumn}
              leftPos={leftMarginDays['sunday']}
              idCalendar={idCalendar}
            />
          );
        })}
      </CalendarWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: auto;
  height: 100%;
  overflow: scroll;
`;
const CalendarWrapper = styled.div`
  position: relative;
  padding-top: 20px;
  margin-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 0;
`;
const SeparationLine = styled.div`
  height: 1px;
  width: 95%;
  background-color: ${veryLightLightGrey};
`;
const CurrentTimeLine = styled.div<{top: number; left:number, width: number}>`
  position: absolute;
  top: ${({top}) => `${top}px`};
  left: ${({left}) => `${left}px`};
  height: 2px;
  width: ${({width}) => `${width}px`};
  background-color: ${buttonRed};
  z-index: 100;
`;
const Tranche = styled.div<{index: number}>`
  width: 100%;
  height: ${heightLineTimeSchedule}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({index}) => index === 0 ? '50px' : 0};
  margin-bottom: ${heightTrancheScheduleTablet}px;
`;
const Circle = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${buttonRed};
`;
const VerticalTranche = styled.div<{index: number, width: number}>`
  position: absolute;
  padding-top: -60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: ${({index, width}) => `${index*width + leftMarginVerticalScheduleTablet}px`};
  height: 100%;
  width: ${({width}) => `${width}px`};
  border-right: 1px solid ${veryLightLightGrey};
`;
const VerticalTrancheDay = styled.div<{index: number, width: number}>`
  display: flex;
  align-items: center;
  width: ${({width}) => `${width}px`};
`;
const WrapperLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: center;
`;
const DaysBar = styled.div`
  position: fixed;
  top: 65px;
  margin-left: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
`;

export default CalendarTablet;